<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Cereri site</h1>
			<v-btn v-if="cereri.length" color="teal" class="uppercase" dark>
				<export-excel :data="cereri" :fields="excel_fields" worksheet="vanzari" name="clients_juridice.xls">
					<v-icon left>
						mdi-download
					</v-icon>
					Excel
				</export-excel>
			</v-btn>
		</div>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta cerere dupa client"></v-text-field>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectZone" v-model="selectedZone" class="w-60" :items="zones" item-text="denumire"
						item-value="value" :clearable="['admin'].includes(user.rol)"
						@click:clear="$refs.selectZone.blur()" label="Toate zonele" solo hide-details>
						<template v-slot:item="{ item }">
							<span>
								{{ item.denumire }}
							</span>
							<span class="w-5 h-5 rounded-full ml-2" :class="'bg-' + item.color"></span>
						</template>
						<template v-slot:selection="{ item }">
							<span>
								{{ item.denumire }}
							</span>
							<span class="w-5 h-5 rounded-full ml-2" :class="'bg-' + item.color"></span>
						</template>
					</v-select>
				</div>
			</div>
		</v-toolbar>
		<v-data-table :headers="headers" :items="filterBy(cereri, filterInput)" show-expand single-expand
			:expanded.sync="expanded" :items-per-page="-1" hide-default-footer>
			<template v-slot:item.date="{ item }">
				{{ item.date_created | formatDateTime }}
			</template>
			<template v-slot:item.name="{ item }">
				{{ item.billing.first_name }} {{ item.billing.last_name }}
			</template>
			<template v-slot:item.judet="{ item }">
				<div :class="zoneColor(item.billing.state)" class="font-bold">{{ zoneName(item.billing.state)
					}}</div>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn text rounded color="primary" :to="'/cererisite/single/' + item.id">detalii</v-btn>
			</template>
			<template v-slot:expanded-item="{ headers, item }">
				<td colspan="1" class="bg-gray-100">
					<v-icon class="mx-1">mdi-arrow-right-bottom</v-icon>
				</td>
				<td :colspan="headers.length - 1" class="bg-gray-100">
					<div class="mt-3 mb-4">
						<p v-for="product in item.line_items" class="mb-1">
							{{ product_name(product) }}
							{{ " | " + product.quantity + " " + product_um(product) }}
						</p>
					</div>
				</td>
			</template>
			<template v-slot:footer>
				<div class="flex flex-col justify-center">
					<v-progress-linear v-if="loadingCereri" indeterminate color="primary"
						class="mb-4"></v-progress-linear>
					<v-btn v-if="cereri.length >= 15" text color="teal" class="uppercase mb-4" @click="loadMore">
						<v-icon>mdi-plus</v-icon>
						Incarca mai multe cereri
					</v-btn>
				</div>
			</template>
		</v-data-table>
		<!-- {{ cereri[0].billing }} -->
	</div>
</template>

<script>
import firebase from "firebase/app";
import moment from "moment";

export default {
	name: "Oferte",
	components: {},
	data() {
		return {
			selectedZone: null,
			expanded: [],
			headers: [
				{
					text: 'Nr.',
					align: 'start',
					sortable: false,
					value: 'id'
				},
				{
					text: 'Data si ora',
					align: 'start',
					sortable: false,
					value: 'date',
				},
				{
					text: 'Client',
					align: 'start',
					sortable: false,
					value: 'name',
				},
				{
					text: 'Tel. client',
					align: 'start',
					sortable: false,
					value: 'billing.phone',
				},
				{
					text: 'Firma',
					align: 'start',
					sortable: false,
					value: 'billing.company'
				},
				{
					text: 'Judet',
					align: 'start',
					sortable: false,
					value: 'judet'
				},
				{
					value: 'data-table-expand',
					width: '5%'
				},
				{
					value: 'actions',
					sortable: false,
					width: '5%'
				}

			],
			isCustomDateRange: false,
			selectedStartMonth: moment().subtract(1, 'months'),
			selectedEndMonth: moment(),
			monthLabels: [
				"ian",
				"feb",
				"mar",
				"apr",
				"mai",
				"iun",
				"iul",
				"aug",
				"sep",
				"oct",
				"noi",
				"dec",
			],
			excel_fields: {
				Date: "date_created",
				Firstname: "billing.first_name",
				Lastname: "billing.last_name",
				Phone: "billing.phone",
				Company: "billing.company",
				Location: "client_obj.judet.text",
				Status: "status"
			},
			user_email: firebase.auth().currentUser.email,
			filterInput: "",
			page: 1,
			arr_orangezone: [
				{
					code: 'AB',
					name: 'Alba'
				},
				{
					code: 'AR',
					name: 'Arad'
				},
				{
					code: 'BH',
					name: 'Bihor'
				},
				{
					code: 'BN',
					name: 'Bistrita-Nasaud'
				},
				{
					code: 'CS',
					name: 'Caras-Severin'
				},
				{
					code: 'CJ',
					name: 'Cluj'
				},
				{
					code: 'DJ',
					name: 'Dolj'
				},
				{
					code: 'GJ',
					name: 'Gorj'
				},
				{
					code: 'HD',
					name: 'Hunedoara'
				},
				{
					code: 'MM',
					name: 'Maramures'
				},
				{
					code: 'MH',
					name: 'Mehedinti'
				},
				{
					code: 'MS',
					name: 'Mures'
				},
				{
					code: 'OT',
					name: 'Olt'
				},
				{
					code: 'SJ',
					name: 'Salaj'
				},
				{
					code: 'SM',
					name: 'Satu Mare'
				},
				{
					code: 'SB',
					name: 'Sibiu'
				},
				{
					code: 'TR',
					name: 'Teleorman'
				},
				{
					code: 'TM',
					name: 'Timis'
				}
			],
			arr_greenzone: [
				{
					code: 'B',
					name: 'Bucuresti'
				},
				{
					code: 'CL',
					name: 'Calarasi'
				},
				{
					code: 'DB',
					name: 'Dambovita'
				},
				{
					code: 'GR',
					name: 'Giurgiu'
				},
				{
					code: 'IL',
					name: 'Ialomita'
				},
				{
					code: 'IF',
					name: 'Ilfov'
				},
				{
					code: 'PH',
					name: 'Prahova'
				}
			],
			arr_violetzone: [
				{
					code: 'AG',
					name: 'Arges'
				},
				{
					code: 'BC',
					name: 'Bacau'
				},
				{
					code: 'BT',
					name: 'Botosani'
				},
				{
					code: 'BR',
					name: 'Braila'
				},
				{
					code: 'BV',
					name: 'Brasov'
				},
				{
					code: 'BZ',
					name: 'Buzau'
				},
				{
					code: 'CV',
					name: 'Covasna'
				},
				{
					code: 'CT',
					name: 'Constanta'
				},
				{
					code: 'GL',
					name: 'Galati'
				},
				{
					code: 'HR',
					name: 'Harghita'
				},
				{
					code: 'IS',
					name: 'Iasi'
				},
				{
					code: 'NT',
					name: 'Neamt'
				},
				{
					code: 'SV',
					name: 'Suceava'
				},
				{
					code: 'TL',
					name: 'Tulcea'
				},
				{
					code: 'VL',
					name: 'Valcea'
				},

				{
					code: 'VS',
					name: 'Vaslui'
				},
				{
					code: 'VN',
					name: 'Vrancea'
				}
			],
		};
	},
	computed: {
		loadingCereri() {
			return this.$store.getters.loadingCereri
		},
		cereri() {
			return this.$store.getters.wpCereri;
		},
		details() {
			return this.$store.getters.wpDetails;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		selectedZoneArr() {
			return this["arr_" + this.selectedZone + "zone"]
		},
		zones() {
			const zonesArr = [
				{
					id: 11,
					denumire: 'Zona portocalie',
					value: 'orange',
					color: 'yellow-600'
				},
				{
					id: 12,
					denumire: 'Zona verde',
					value: 'green',
					color: 'green-600'
				},
				{
					id: 13,
					denumire: 'Zona violet',
					value: 'violet',
					color: 'pink-600'
				}
			]

			if (this.user.rol !== "admin") {
				return zonesArr.filter((zone) => zone.value == this.user.zona)
			} else {
				return zonesArr
			}
		}
	},
	watch: {
		user(value, oldvalue) {
			if (value != oldvalue) {
				this.fetchCereri();
			}
		},
	},
	methods: {
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);
			if (this.selectedZoneArr && this.selectedZoneArr.length > 0) {
				return list.filter((cerere) => searchRegex.test(this.fullName(cerere.billing.first_name, cerere.billing.last_name)) && this.selectedZoneArr.map(jud => jud.code).includes(cerere.billing.state))
			} else {
				return list.filter((cerere) => searchRegex.test(this.fullName(cerere.billing.first_name, cerere.billing.last_name)))
			}

		},
		zoneColor(judet) {
			if (this.arr_orangezone.map(jud => jud.code).includes(judet)) {
				return 'text-yellow-600'
			}
			if (this.arr_greenzone.map(jud => jud.code).includes(judet)) {
				return 'text-green-600'
			}
			if (this.arr_violetzone.map(jud => jud.code).includes(judet)) {
				return 'text-pink-600'
			}
			return ''
		},
		zoneName(judet) {
			const allStates = this.arr_orangezone.concat(this.arr_greenzone, this.arr_violetzone);
			const state = allStates.find(item => item.code == judet)
			return state.name
		},
		fullName(firstname, lastname) {
			return `${firstname} ${lastname}`;
		},
		fetchUser() {
			let mail = this.user_email;
			this.$store.dispatch("fetchUser", mail);
		},
		fetchCereri() {
			this.$store.dispatch("fetchWPCereri");
		},
		loadMore() {
			this.page = this.page + 1;
			this.$store.dispatch("fetchMoreWPCereri", this.page);
		},
		product_um(product) {
			let um_obj = product.meta_data.find(
				(obj) => obj.key == "UM"
			);
			if (um_obj != undefined || um_obj != null) {
				return um_obj.value;
			}
		},
		product_dim(product) {
			let dim_obj = product.meta_data.find(
				(obj) => obj.key == "dimensiune"
			);
			if (dim_obj != undefined || dim_obj != null) {
				return dim_obj.value;
			}
		},
		product_name(product) {
			let name = product.name;
			let str = name.slice(0, name.indexOf(",") + 1);
			str += " " + this.product_dim(product);
			return str;
		},
	},
	created: function () {
		this.fetchUser();
	},
	mounted: function () {
		this.selectedZone = this.user.zona
	}
};
</script>

<style></style>

<template>
	<div class="container">
		<div class="flex justify-between my-6">
			<h4 class="text-4xl my-4">Facturi Proforme Productie</h4>
			<div class=" monthpicker">
				<span>Selecteaza luna: </span>
				<vue-monthly-picker class="month-picker" :monthLabels="monthLabels" dateFormat="MMMM YYYY"
					:clearOption="false" v-model="selectedMonth"
					@selected="fetchProformeProductie"></vue-monthly-picker>
			</div>
		</div>
		<div v-if="['admin', 'superuser'].includes(user.rol)" class="grid grid-cols-6 gap-10">
			<div class="col-span-2"></div>
			<div class="bg-white p-6 shadow-md">
				CA fara TVA
				<p class="text-xl text-black font-bold mt-1">{{ totalOferteProforme }} ron</p>
			</div>
			<div class="bg-white p-6 shadow-md">
				Total proforme
				<p class="text-xl text-black font-bold mt-1">{{ totalProforme }} ron</p>
			</div>
			<div class="bg-white p-6 shadow-md">
				Total proforme platite
				<p class="text-xl text-blue-500 font-bold mt-1">
					{{ totalProformePlatite }} ron
				</p>
			</div>
			<div class="bg-white p-6 shadow-md">
				Total proforme incasate
				<p class="text-xl text-green-500 font-bold mt-1">
					{{ totalProformeIncasate }} ron
				</p>
			</div>
		</div>

		<div class="input-field filter-input w-1/2" v-if="proforme.length > 0">
			<input placeholder="Cauta proforma dupa client" v-model="filterInput" />
		</div>

		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>

		<div class="card-panel" v-else>
			<div v-if="proforme.length > 0">
				<table class="highlight responsive-table">
					<thead>
						<tr>
							<th>Nr.</th>
							<th>Data proforma</th>
							<th class="width30">Client</th>
							<th>Oferta</th>
							<th>Tip lucrare</th>
							<th>Valoare proforma</th>
							<th>Valoare oferta</th>
							<th v-if="user.rol === 'admin'">Incasat?</th>
							<th v-else-if="user.rol === 'superuser'">Platit?</th>
							<th>Intocmit de</th>
							<th>Descarca</th>
							<th>Sterge</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(proforma, index) in filterBy(proforme, filterInput)" :key="index">
							<td>{{ proforma.id_proforma }}</td>
							<td>{{ proforma.data | formatDate }}</td>
							<td>{{ proforma.nume }}</td>
							<td>{{ proforma.oferta_id }}</td>
							<td>{{ proforma.tip_lucrare }}</td>
							<td>{{ proforma.suma_proforma }} ron</td>
							<td>{{ proforma.suma_oferta }} ron</td>
							<td class="center-align no-select" @click="changePaidStatus(proforma.id_proforma)">
								<i class="material-icons btn-icon" :class="proforma.paid_status == 'incasata'
					? 'green-text'
					: proforma.paid_status == 'platita'
						? 'blue-text'
						: ''
					">paid</i>
							</td>
							<td>{{ proforma.username }}</td>
							<td class="center-align" @click="downloadProforma(proforma.id_proforma)">
								<i class="material-icons btn-icon teal-text">cloud_download</i>
							</td>
							<td class="center-align" @click="selectedProforma(proforma.id_proforma)">
								<i data-target="modal_delete"
									class="material-icons btn-icon grey-text delete-icon modal-trigger">delete_forever</i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else class="py-10">
				Nu exista proforme de afisat.
			</div>
		</div>

		<!-- Modal Delete -->
		<div id="modal_delete" class="modal modal-delete">
			<div class="modal-content">
				<h6>Sterge definitiv proforma nr. {{ delID }}?</h6>
				<br />
				<button class="btn red" v-on:click="deleteProforma()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>

		<!-- <pre>{{ proforme }}</pre> -->
		<!-- <pre>{{firstday}} {{lastday}}</pre> -->
		<!-- <pre>{{ user }}</pre> -->
	</div>
</template>

<script>
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
import images from "../../assets/oferta_images.js";

export default {
	name: "ProformeProductie",
	components: {
		VueMonthlyPicker,
	},
	data() {
		return {
			filterInput: "",
			selectedMonth: moment(),
			monthLabels: [
				"ian",
				"feb",
				"mar",
				"apr",
				"mai",
				"iun",
				"iul",
				"aug",
				"sep",
				"oct",
				"noi",
				"dec",
			],
			delID: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		proforme() {
			return this.$store.getters.proformeProductie;
		},
		firstday() {
			if (this.selectedMonth) {
				return this.selectedMonth.startOf("month").format("YYYY-MM-DD");
			}
		},
		lastday() {
			if (this.selectedMonth) {
				return this.selectedMonth.endOf("month").format("YYYY-MM-DD");
			}
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		totalOferteProforme() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				let p = Number(proforma.suma_oferta) / 1.19
				suma += p
			});
			return suma.toFixed(2);
		},
		totalProforme() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				suma += Number(proforma.suma_proforma);
			});
			return suma.toFixed(2);
		},
		totalProformePlatite() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				if (proforma.paid_status !== "neplatita") {
					suma += Number(proforma.suma_proforma);
				}
			});
			return suma.toFixed(2);
		},
		totalProformeIncasate() {
			let suma = 0;
			this.proforme.forEach((proforma) => {
				if (proforma.paid_status === "incasata") {
					suma += Number(proforma.suma_proforma);
				}
			});
			return suma.toFixed(2);
		},
	},
	watch: {},
	methods: {
		replaceSpecialCh(str) {
			return str
				.replace(/ș|Ș/g, "s")
				.replace(/ț|Ț/g, "t")
				.replace(/î|Î/g, "i")
				.replace(/ă|â|Â|Ă/g, "a");
		},
		formatPhoneNo(str) {
			if (str.charAt(0) == "+" || str.charAt(0) == "(") {
				return str;
			} else {
				return "+4" + str;
			}
		},
		dimensiune_text(produs) {
			let str = produs.dimL;
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value) {
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);

			return list.filter((proforma) => {
				if (this.user.rol == "partener" || this.user.rol == "user") {
					return (
						searchRegex.test(proforma.nume) &&
						proforma.username == this.user.username
					);
				} else {
					return searchRegex.test(proforma.nume);
				}
			});
		},
		changePaidStatus(id) {
			let proforma = this.proforme.find(
				(proforma) => proforma.id_proforma == id
			);
			let status = "neplatita";

			if (this.user.rol === "admin") {
				if (proforma.paid_status == "neplatita") {
					status = "platita";
				} else if (proforma.paid_status == "platita") {
					status = "incasata";
				}
			} else if (this.user.rol === "superuser" || this.user.rol === 'user_productie') {
				if (proforma.paid_status == "neplatita") {
					status = "platita";
				} else if (proforma.paid_status == "incasata") {
					return;
				}
			} else {
				return;
			}

			this.$store.dispatch("updateProformaProductiePaidStatus", {
				id: id,
				paid_status: status,
				email_agent: proforma.email_user,
				data_proforma: proforma.data
			});
		},
		fetchProformeProductie() {
			this.$store.dispatch("fetchProformeProductie", {
				date1: this.firstday,
				date2: this.lastday,
			});
		},
		downloadProforma(id) {
			let proforma = this.proforme.find(
				(proforma) => proforma.id_proforma == id
			);
			let pdfName = `Proforma nr. ${proforma.id_proforma} ${proforma.nume}`;
			var imgData = images.data.logo_istruct_cuprolli;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);

			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 9, 50, 11);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 10, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text(["CUI/CIF: RO 35671874", "RC: J35/468/2016"], 10, 35, {
				lineHeightFactor: 1.25,
			});
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				10,
				43
			);
			// doc.text("Dumbravita, str. Tarcului nr. 7 ap. 5, jud. Timis", 10, 43);
			doc.setFont("Roboto", "bold");
			doc.text(
				["Banca: Banca Transilvania", "IBAN: RO42 BTRL RONC RT03 3757 8301"],
				10,
				53
			);
			doc.setFont("Roboto", "normal");
			doc.text(
				[
					"Dl/Dna: " + proforma.username,
					"Email: " + proforma.email_user,
					"Tel: " + proforma.tel_user,
				],
				10,
				62,
				{
					lineHeightFactor: 1.35,
				}
			);
			doc.setFont("Roboto", "bold");
			doc.text("Cota TVA: 19%", 10, 78);
			doc.setFont("Roboto", "normal");

			//
			// Header - Right side
			let data = moment(String(proforma.data)).format("DD.MM.YYYY");
			doc.setFontSize(16);
			// doc.setTextColor(243, 153, 46);
			doc.setTextColor(160, 171, 192);
			doc.text("FACTURA  PROFORMA", 130, 15);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + proforma.id_proforma + " / " + data, 130, 20);

			// Client data
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Client", 130, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(proforma.nume);
			let drop = 35;
			let numeSplit = doc.splitTextToSize(nume, 62);
			doc.text(numeSplit, 130, drop, {
				lineHeightFactor: 1.35,
			});
			if (nume.length <= 44) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (proforma.cui) {
				doc.text("CUI/CIF: " + proforma.cui, 130, drop);
				drop = drop + 4;
			}

			if (proforma.adresa) {
				let adresa = this.replaceSpecialCh(proforma.adresa);
				if (proforma.oras) {
					adresa += ", " + this.replaceSpecialCh(proforma.oras);
				}
				let adresaSplit = doc.splitTextToSize(adresa, 62);
				doc.text(adresaSplit, 130, drop);
				if (adresa.length != 0 && adresa.length <= 44) {
					drop = drop + 4;
				} else if (adresa.length != 0 && adresa.length <= 73) {
					drop = drop + 8;
				} else if (adresa.length != 0) {
					drop = drop + 12;
				}
			}

			drop = drop + 1;

			let details = [];
			if (proforma.persoana) {
				details.push("Dl/Dna: " + proforma.persoana);
			}
			if (proforma.email) {
				details.push("Email: " + proforma.email);
			}
			if (proforma.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(proforma.tel));
			}
			doc.text(details, 130, drop, {
				lineHeightFactor: 1.35,
			});

			// Table header
			doc.setFont("Roboto", "bold");
			doc.text("Nr.", 10, 86);
			doc.text("Denumire produs", 18, 86);
			doc.text("Cantitate", 128, 86);
			doc.text("Pret unitar", 152, 86);
			doc.text("Valoare fara TVA", 174, 86);
			doc.setDrawColor(150);
			doc.setLineWidth(0.4);
			doc.line(10, 88, 200, 88);
			let space = 93;
			doc.setFont("Roboto", "normal");

			doc.text("1", 11, space);
			let oferta_data = moment(String(proforma.oferta_data)).format(
				"DD.MM.YYYY"
			);
			doc.text(
				[
					proforma.denumire_produs,
					`Avans ${proforma.procent_proforma}%, conform ofertei nr. ${proforma.oferta_id} din ${oferta_data}`,
				],
				18,
				space,
				{ lineHeightFactor: 1.5 }
			);
			doc.text(proforma.buc_lucrare + " buc", 135, space, {
				align: "center",
			});

			// let pret_unitar = proforma.suma_oferta / 1.19;
			let pret_unitar = proforma.suma_proforma / 1.19;
			let valoare = pret_unitar * proforma.buc_lucrare;
			doc.text(pret_unitar.toFixed(2) + " ron", 170, space, { align: "right" });
			doc.text(valoare.toFixed(2) + " ron", 197, space, { align: "right" });

			doc.line(10, space + 8, 200, space + 8);
			space = space + 12;

			// // Footer  - Sum Totals
			// doc.text("Total fara TVA", 147, space + 2);
			// doc.text(pret_unitar.toFixed(2) + " ron", 197, space + 2, {
			// 	align: "right",
			// });
			// doc.text("TVA", 162, space + 7);
			// let tva = pret_unitar * 0.19;
			// doc.text(tva.toFixed(2) + " ron", 197, space + 7, {
			// 	align: "right",
			// });
			// doc.text("TOTAL", 158, space + 12);
			// let total = Number(pret_unitar) + Number(tva);
			// doc.text(total.toFixed(2) + " ron", 197, space + 12, {
			// 	align: "right",
			// });
			// doc.line(10, space + 16, 200, space + 16);

			// space = space + 20;

			// if (proforma.procent_proforma < 100) {
			doc.setFont("Roboto", "bold");
			// doc.text(
			// 	`Total de plata in avans: ${proforma.procent_proforma}%`,
			// 	12,
			// 	space + 2
			// );
			let val_avans = proforma.suma_proforma / 1.19;
			doc.text("Total fara TVA", 147, space + 2);
			doc.text(val_avans.toFixed(2) + " ron", 197, space + 2, {
				align: "right",
			});
			doc.text("TVA", 162, space + 7);
			let tva_avans = val_avans * 0.19;
			doc.text(tva_avans.toFixed(2) + " ron", 197, space + 7, {
				align: "right",
			});
			doc.text("TOTAL", 158, space + 12);
			let total_avans = Number(val_avans) + Number(tva_avans);
			doc.text(total_avans.toFixed(2) + " ron", 197, space + 12, {
				align: "right",
			});
			doc.line(10, space + 16, 200, space + 16);
			// }
			doc.save(pdfName + ".pdf");
		},
		selectedProforma(id) {
			this.delID = id;
		},
		deleteProforma() {
			this.$store.dispatch("deleteProformaProductie", {
				id: this.delID,
				date1: this.firstday,
				date2: this.lastday,
			});
			M.Modal.getInstance(document.querySelector(".modal-delete")).close();
		},
	},
	created: function () {
		this.fetchProformeProductie();
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style>
.delete-icon:hover {
	color: red !important;
}
</style>

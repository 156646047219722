<template>
	<div class="container pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Oferte configurator RO</h1>
			<div class="flex justify-end item-center">
				<v-badge bordered color="error" :content="reveniriCounts.due"
					:value="user.rol != 'admin' && reveniriCounts.due > 0" offset-x="20" overlap>
					<v-badge bordered color="warning" :content="reveniriCounts.today"
						:value="user.rol != 'admin' && reveniriCounts.today > 0" offset-x="0" overlap>
						<v-btn to="/oferte-productie/lista-reveniri-ro" color="white" class="uppercase">Lista
							reveniri</v-btn></v-badge></v-badge>
			</div>
		</div>
		<v-dialog v-model="visibleInteresModal" max-width="400px">
			<v-card>
				<v-form ref="changeInteresForm" v-model="interesForm" @submit.prevent="saveInteresStatus">
					<v-card-title>
						Schimba statusul
					</v-card-title>
					<v-card-text>
						<v-select v-model="currentOffer.status"
							:items="['e interesat', 'nu a raspuns', 'revenim', 'nu e interesat']" label="Alege" outlined
							:rules="[rules.required]">
						</v-select>
						<v-text-field v-model="currentOffer.details" label="Detalii suplimentare"
							outlined></v-text-field>
						<v-menu v-if="currentOffer.status == 'revenim'" v-model="datepickerMenu"
							:close-on-content-click="false" :nudge-right="40" transition="scale-transition"
							min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="formattedReturnDate" label="Data revenire"
									prepend-inner-icon="mdi-calendar" readonly outlined :rules="[rules.required]"
									v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="currentOffer.returnDate" no-title :rules="[rules.required]"
								@input="datepickerMenu = false"></v-date-picker>
						</v-menu>
					</v-card-text>
					<div class="flex flex-row justify-end gap-2 px-6 pb-4">
						<v-btn color="blue-grey" class="uppercase" dark large
							@click="closeInteresModal">Anuleaza</v-btn>
						<v-btn color="teal" dark class="uppercase" large type="submit">Salveaza</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta oferta dupa client"></v-text-field>
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectZone" v-model="selectedZone" class="w-60" :items="zones" item-text="denumire"
						item-value="value" :clearable="['admin', 'superuser'].includes(user.rol)"
						@click:clear="$refs.selectZone.blur()" label="Toate zonele" solo hide-details>
						<template v-slot:item="{ item }">
							<span>
								{{ item.denumire }}
							</span>
							<span class="w-5 h-5 rounded-full ml-2" :class="'bg-' + item.color"></span>
						</template>
						<template v-slot:selection="{ item }">
							<span>
								{{ item.denumire }}
							</span>
							<span class="w-5 h-5 rounded-full ml-2" :class="'bg-' + item.color"></span>
						</template>
					</v-select>
				</div>
			</div>
		</v-toolbar>

		<div class="input-field filter-input flex flex-row items-center justify-end">
			<div class="flex flex-row items-center gap-2" v-if="user.rol == 'admin' || user.rol == 'superuser'">
				<label>
					<input type="checkbox" v-model="isCustomDateRange" />
					<span>Pe o anumita perioada</span>
				</label>
				<div v-if="isCustomDateRange" class="flex gap-4">
					<div class="monthpicker flex items-center gap-2">
						<vue-monthly-picker class="month-picker" :monthLabels="monthLabels" dateFormat="MMMM YYYY"
							:clearOption="false" v-model="selectedStartMonth"></vue-monthly-picker>
						<vue-monthly-picker class="month-picker" :monthLabels="monthLabels" dateFormat="MMMM YYYY"
							:clearOption="false" v-model="selectedEndMonth"></vue-monthly-picker>
					</div>
					<button class="btn" @click="fetchRangeOferteProductieConfiguratorGard">
						Incarca
					</button>
					<button v-if="user.rol == 'admin'" class="btn">
						<export-excel :data="oferte_productie" :fields="excel_fields" worksheet="configurator"
							name="clients.xls"><i class="material-icons left">download</i>Excel</export-excel>
					</button>
				</div>
			</div>
		</div>


		<v-data-table :headers="headers" :items="filterBy(oferte_productie, filterInput)" item-key="id_cerere"
			:loading="loadingOferteConfigurator" show-expand single-expand :expanded.sync="expanded"
			:items-per-page="-1" hide-default-footer>
			<template v-slot:item.data="{ item }">
				{{ item.data | formatDate }}
			</template>
			<template v-slot:item.nume="{ item }">
				{{ `${item.client_obj.surname} ${item.client_obj.name}` }}
			</template>
			<template v-slot:item.bricks="{ item }">
				<span v-if="['admin', 'superuser'].includes(user.rol)">
					<v-icon v-if="!!+item.bricks_selected" color="orange">mdi-wall</v-icon>
				</span>
			</template>
			<template v-slot:item.sunat="{ item }">
				<Called :item="item" :username="user.username" />
			</template>
			<template v-slot:item.interes="{ item }">
				<Interes :item="item" @openModal="loadCurrentOffer" />
			</template>
			<template v-slot:item.trimisa="{ item }">
				<Sent :item="item" />
			</template>
			<template v-slot:item.judet="{ item }">
				<div :class="zoneColor(item.client_obj.judet.value)" class="font-bold">{{ item.client_obj.judet.text
					}}</div>
			</template>
			<template v-slot:item.suprafata="{ item }">
				<AreaTotal :item="item" />
			</template>
			<template v-slot:item.total="{ item }">
				<span class="font-medium">
					{{ item.gard_obj.pretTotal }}
				</span> eur
			</template>
			<template v-slot:expanded-item="{ headers, item }">
				<td colspan="2" class="bg-gray-100">
					<v-icon class="mx-1">mdi-arrow-right-bottom</v-icon>
				</td>
				<td :colspan="7" class="bg-gray-100">
					<div class="mt-3 mb-4 flex flex-col">
						<div>Email: {{ item.client_obj.email }}</div>
						<div>Tel: {{ item.client_obj.phone }}</div>
					</div>
				</td>
				<td colspan="3" class="bg-gray-100">
					<Prices :item="item" />
				</td>
			</template>
			<template v-slot:item.actions="{ item }">
				<Actions :item="item" :user="user" :today="today" />
			</template>
			<template v-slot:footer>
				<div v-if="lastId > 1" class="flex flex-col justify-center">
					<v-progress-linear v-if="loadingOferteConfigurator" indeterminate color="primary"
						class="mb-4"></v-progress-linear>
					<v-btn text color="teal" class="uppercase my-2" @click="loadMoreOferte">
						<v-icon>mdi-plus</v-icon>
						Incarca mai multe oferte
					</v-btn>
				</div>
			</template>
		</v-data-table>

		<!-- <pre>{{ oferte_productie[0] }}</pre> -->
	</div>
</template>

<script>
import moment from "moment"
import VueMonthlyPicker from "vue-monthly-picker"
import Called from "./OferteConfigurator/Called"
import Interes from "./OferteConfigurator/Interes"
import Sent from "./OferteConfigurator/Sent"
import AreaTotal from "./OferteConfigurator/AreaTotal"
import Prices from "./OferteConfigurator/Prices"
import Actions from "./OferteConfigurator/Actions"
// import jsPDF from "jspdf";
// import fonts from "../../assets/fonts/fonts.js";
// import images from "../../assets/oferta_images.js";

export default {
	name: "OferteProductieConfiguratorGard",
	components: {
		VueMonthlyPicker,
		Called,
		Interes,
		Sent,
		AreaTotal,
		Prices,
		Actions
	},
	data() {
		return {
			visibleInteresModal: false,
			interesForm: false,
			datepickerMenu: false,
			rules: {
				required: value => !!value || 'Câmp obligatoriu'
			},
			today: moment().format("YYYY-MM-DD"),
			isCustomDateRange: false,
			selectedStartMonth: moment().subtract(1, 'months'),
			selectedEndMonth: moment(),
			selectedZone: null,
			monthLabels: [
				"ian",
				"feb",
				"mar",
				"apr",
				"mai",
				"iun",
				"iul",
				"aug",
				"sep",
				"oct",
				"noi",
				"dec",
			],
			filterInput: "",
			expanded: [],
			headers: [
				{
					text: 'Nr.',
					align: 'start',
					sortable: false,
					value: 'id_cerere'
				},
				{
					text: 'Data',
					align: 'start',
					sortable: false,
					value: 'data',
				},
				{
					text: 'Client',
					align: 'start',
					sortable: false,
					value: 'nume',
				},
				{
					text: '',
					align: 'start',
					sortable: false,
					value: 'bricks',
				},
				{
					text: 'Sunat?',
					align: 'center',
					sortable: false,
					value: 'sunat',
					width: '5%'
				},
				{
					text: 'Interes',
					align: 'start',
					sortable: false,
					value: 'interes',
					width: '15%'
				},
				{
					text: 'Trimis oferta?',
					align: 'start',
					sortable: false,
					value: 'trimisa',
				},
				{
					text: 'Judet',
					align: 'start',
					sortable: false,
					value: 'judet',
				},
				{
					text: 'Suprafata totala',
					align: 'start',
					sortable: false,
					value: 'suprafata',
				},
				{
					text: 'Pret total cu TVA',
					align: 'start',
					sortable: false,
					value: 'total',
					width: '10%'
				},
				{
					value: 'data-table-expand',
					width: '5%'
				},
				{
					value: 'actions',
					sortable: false,
					width: '5%'
				}
			],
			prices: {
				p_rama: 1.2, // %
				pp: 145, // eur
				pa: 35, // eur
				pap: 145, // eur
			},
			new_prices: {
				p_rama: 1.28, // %
				pp: 265, // eur
				pa: 45, // eur
				pap: 115, // eur
			},
			new_new_prices: {
				p_rama: 1.245, // %
				pp: 235, // eur
				pa: 75, // eur
				pap: 115, // eur
			},
			newest_prices: {
				p_rama: 1.245, // %
				pp: 235, // eur
				pa: 100, // eur
				pap: 210, // eur
			},
			newest_prices2: {
				p_rama: 1.191, // %
				pp: 236, // eur
				pa: 115, // eur
				pap: 247, // eur
			},
			newest_prices3: {
				p_rama: 1.27, // %
				pp: 237, // eur
				pa: 126, // eur
				pap: 254, // eur
			},
			newest_prices4: {
				p_rama: 35, // %
				pp: 212, // eur
				pa: 122, // eur
				pap: 252, // eur
			},
			partener_prices: {
				add_p: 7, // %
				p_rama: 1.233, // %
				pp: 228, // eur
				pa: 103, // eur
				pap: 178, // eur
			},
			new_partener_prices: {
				add_p: 7, // %
				p_rama: 1.233, // %
				pp: 228, // eur
				pa: 128, // eur
				pap: 273, // eur
			},
			suprafata_limita_panou: 0.1,
			suprafata_limita_pp: 1.9,
			suprafata_limita_pa: 6.7,
			suprafata_limita_pa_pp_included: 4.8,
			arr_orangezone: [
				'Alba',
				'Arad',
				'Bihor',
				'Bistrita-Nasaud',
				'Caras-Severin',
				'Cluj',
				'Hunedoara',
				'Maramures',
				'Mures',
				'Satu Mare',
				'Salaj',
				'Sibiu',
				'Timis',
			],
			arr_bluezone: [
				'Arges',
				'Brasov',
				'Dolj',
				'Gorj',
				'Mehedinti',
				'Olt',
				'Teleorman',
				'Valcea',
			],
			arr_greenzone: [
				'Bucuresti',
				'Ilfov',
				'Giurgiu',
				'Dambovita',
				'Prahova',
				'Ialomita',
				'Calarasi'
			],
			arr_violetzone: [
				'Bacau',
				'Botosani',
				'Braila',
				'Buzau',
				'Covasna',
				'Constanta',
				'Galati',
				'Harghita',
				'Iasi',
				'Neamt',
				'Suceava',
				'Tulcea',
				'Vaslui',
				'Vrancea',
			],
			currentOffer: {
				id: null,
				status: null,
				details: null,
				returnDate: null
			},
			excel_fields: {
				Date: "data",
				Name: "client_obj.name",
				Surname: "client_obj.surname",
				Email: "client_obj.email",
				Phone: "client_obj.phone",
				Location: "client_obj.judet.text",
			}
		};
	},
	computed: {
		selectedZoneArr() {
			return this["arr_" + this.selectedZone + "zone"]
		},
		oferte_productie() {
			return this.$store.getters.oferte_productie_configurator_gard;
		},
		loading() {
			return this.$store.getters.loading;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		lastId() {
			if (this.oferte_productie.length > 0) {
				return this.oferte_productie[this.oferte_productie.length - 1]
					.id_cerere;
			} else {
				return null;
			}
		},
		firstday() {
			if (this.selectedStartMonth) {
				return this.selectedStartMonth.startOf("month").format("YYYY-MM-DD");
			}
		},
		lastday() {
			if (this.selectedEndMonth) {
				return this.selectedEndMonth.endOf("month").format("YYYY-MM-DD");
			}
		},
		loadingOferteConfigurator() {
			return this.$store.getters.loadingOferteConfigurator;
		},
		zones() {
			const zonesArr = [
				{
					id: 1,
					denumire: 'Zona portocalie',
					value: 'orange',
					color: 'yellow-600'
				},
				{
					id: 2,
					denumire: 'Zona albastra',
					value: 'blue',
					color: 'blue-700'
				},
				{
					id: 3,
					denumire: 'Zona verde',
					value: 'green',
					color: 'green-600'
				},
				{
					id: 4,
					denumire: 'Zona violet',
					value: 'violet',
					color: 'pink-600'
				}
			]

			if (this.user.rol === "user_productie") {
				return zonesArr.filter((zone) => zone.value == this.user.zona)
			} else {
				return zonesArr
			}
		},
		formattedReturnDate() {
			return this.$options.filters.formatDate(this.currentOffer.returnDate)
		},
		reveniriCounts() {
			return this.$store.getters.lista_reveniri_configurator_gard_counts
		}
	},
	methods: {
		escapeRegExp(s) {
			return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
		},
		filterBy(list, value) {
			if (!value) {
				value = ""
			}
			const words = value
				.split(/\s+/g)
				.map((s) => s.trim())
				.filter((s) => !!s);

			const hasTrailingSpace = value.endsWith(" ");

			const searchRegex = new RegExp(
				words
					.map((word, i) => {
						if (i + 1 === words.length && !hasTrailingSpace) {
							// The last word - ok with the word being "startswith"-like
							return `(?=.*\\b${this.escapeRegExp(word)})`;
						} else {
							// Not the last word - expect the whole word exactly
							return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
						}
					})
					.join("") + ".+",
				"i"
			);
			if (this.selectedZoneArr && this.selectedZoneArr.length > 0) {
				return list.filter((oferta) => searchRegex.test(this.fullName(oferta.client_obj.surname, oferta.client_obj.name)) && this.selectedZoneArr.includes(oferta.client_obj.judet.text))
			} else {
				return list.filter((oferta) => searchRegex.test(this.fullName(oferta.client_obj.surname, oferta.client_obj.name)))
			}

		},
		returnTimeframeNumber(str) {
			if (str) {
				if (str == "cat mai repede posibil") {
					return 1;
				} else if (str == "in curand") {
					return 2;
				} else if (str == "in termen de jumatate de an") {
					return 3;
				} else if (str == "deocamdata ma intereseaz doar") {
					return 4;
				}
			}
		},
		returnTimeframeColor(str) {
			if (str) {
				if (str == "cat mai repede posibil" || str == "in curand") {
					return "green";
				} else {
					return "grey";
				}
			}
		},
		zoneColor(judet) {
			if (this.arr_orangezone.includes(judet)) {
				return 'text-yellow-600'
			}
			if (this.arr_bluezone.includes(judet)) {
				return 'text-blue-700'
			}
			if (this.arr_greenzone.includes(judet)) {
				return 'text-green-600'
			}
			if (this.arr_violetzone.includes(judet)) {
				return 'text-pink-600'
			}
			return ''
		},
		loadMoreOferte() {
			this.$store.dispatch(
				"fetchNextOferteProductieConfiguratorGard",
				this.lastId
			);
		},
		closeInteresModal() {
			this.visibleInteresModal = false
			this.$nextTick(() => {
				this.currentOffer = Object.assign({}, {})
			})
		},
		loadCurrentOffer(payload) {
			this.currentOffer = Object.assign({}, { ...payload })
			this.visibleInteresModal = true
		},
		async saveInteresStatus() {
			const valid = await this.$refs.changeInteresForm.validate();

			if (valid) {
				this.$store.dispatch("updateInteresStatus", {
					id: this.currentOffer.id,
					interes: this.currentOffer.status,
					details: this.currentOffer.details,
					return_date: this.currentOffer.returnDate,
					user_id: this.user.id_user
				});
				this.closeInteresModal();
			}
		},
		fullName(firstname, lastname) {
			return `${firstname} ${lastname}`;
		},
		replaceSpecialCh(str) {
			return str
				.replace(/ș|Ș/g, "s")
				.replace(/ț|Ț/g, "t")
				.replace(/î|Î/g, "i")
				.replace(/ă|â|Â|Ă/g, "a");
		},
		fetchRangeOferteProductieConfiguratorGard() {
			this.$store.dispatch("fetchOferteProductieConfiguratorGardForRange", { date1: this.firstday, date2: this.lastday });
		},
	},
	created: function () {
		this.$store.dispatch("fetchOferteProductieConfiguratorGard");
	},
	mounted: function () {
		this.selectedZone = this.user.zona
		this.$store.dispatch("fetchListaReveniriConfiguratorGardCounts");
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.dot {
	border-radius: 100px;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin: 0 5px 0 15px;
}

span.square {
	width: 12px;
	height: 12px;
	display: inline-block;
	margin: 0 5px 0 10px;
}
</style>

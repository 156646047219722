<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Clienti</h1>
			<v-dialog v-model="addClientModal" max-width="600px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="teal" class="uppercase" dark v-bind="attrs" v-on="on">
						Adauga client nou
					</v-btn>
				</template>
				<AddClient @closeModal="addClientModal = false" />
			</v-dialog>
		</div>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="searchClient" flat solo hide-details clearable
						@click:clear="resetInitialClienti" prepend-inner-icon="mdi-magnify"
						label="Cauta dupa nume"></v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchClients">Cauta</v-btn>
				</div>
				<v-pagination v-if="!searchClient" v-model="currentPage" class="v-page" color="blue-grey"
					:length="pageLength" :total-visible="7" elevation="0"></v-pagination>
			</div>
		</v-toolbar>

		<v-data-table :headers="headers" :items="clienti" item-key="id_client" :page.sync="currentPage"
			:loading="loadingClienti" :server-items-length="clientiCount" show-expand single-expand
			:expanded.sync="expanded" :items-per-page="-1" hide-default-footer>
			<template v-slot:item.actions="{ item }">
				<v-btn text rounded color="primary" :to="'/clienti/single/' + item.id_client">detalii</v-btn>
			</template>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="bg-gray-100">
					<div class="flex items-center">
						<v-icon class="mx-1">mdi-map-marker</v-icon>
						<span>Adresa: <span>{{ item.adresa }}</span></span>
					</div>
				</td>

			</template>
		</v-data-table>
		<div class="flex flex-row justify-end my-4">
			<v-pagination v-if="!searchClient" v-model="currentPage" class="v-page" color="blue-grey"
				:length="pageLength" :total-visible="7" elevation="0"></v-pagination>
		</div>

	</div>
</template>

<script>
import AddClient from "@/components/Clients/AddClient";

export default {
	name: "Clienti",
	data() {
		return {
			addClientModal: false,
			searchClient: null,
			filterInput: "",
			currentPage: 1,
			expanded: [],
			headers: [
				{
					text: 'Nume',
					align: 'start',
					sortable: false,
					value: 'nume'
				},
				{
					text: 'CUI/CIF',
					align: 'start',
					sortable: false,
					value: 'cui'
				},
				{
					text: 'Persoana',
					align: 'start',
					sortable: false,
					value: 'persoana',
				},
				{
					text: 'Email',
					align: 'start',
					sortable: false,
					value: 'email',
				},
				{
					text: 'Telefon',
					align: 'start',
					sortable: false,
					value: 'tel',
				},
				{
					value: 'data-table-expand',
					width: '5%'
				},
				{
					value: 'actions',
					sortable: false,
					width: '5%'
				}
			],
		};
	},
	computed: {
		clientiCount() {
			return this.$store.getters.clientiCount
		},
		clienti() {
			return this.$store.getters.clienti;
		},
		loadingClienti() {
			return this.$store.getters.loadingClienti;
		},
		pageLength() {
			return this.clientiCount ? Math.ceil(this.clientiCount / 500) : 1
		}
	},
	watch: {
		currentPage(newVal, oldVal) {
			if (newVal != oldVal) {
				this.fetchClienti(newVal)
			}
		}
	},
	methods: {
		// filterBy(list, value) {
		// 	value = value.charAt(0).toLowerCase() + value.slice(1);
		// 	return list.filter(function (client) {
		// 		return client.nume.toLowerCase().indexOf(value) > -1;
		// 	});
		// },
		fetchClienti(page) {
			this.$store.dispatch("fetchClientiData", page);
		},
		searchClients() {
			this.$store.dispatch("fetchClientiDataBySearchTerm", this.searchClient)
		},
		resetInitialClienti() {
			this.$store.dispatch("fetchClientiCount");
			this.fetchClienti(1)
		}
	},
	components: {
		AddClient,
	},
	created: function () {
		this.$store.dispatch("fetchClientiCount");
		if (!this.clienti.length || this.searchClient == null) {
			this.fetchClienti(1);
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>

<template>
	<div v-if="client" class="container pt-6">
		<v-progress-linear v-if="loadingClient" indeterminate color="teal darken-1" class="mb-4"></v-progress-linear>
		<div v-else>
			<div class="py-2 mb-4">
				<div v-if="!editMode" class="flex flex-row justify-between items-center">
					<div class="flex flex-row justify-start items-center gap-8">
						<v-btn color="grey darken-1" class="uppercase" dark to="/clienti">
							<v-icon>mdi-chevron-left</v-icon>
							Inapoi
						</v-btn>
						<h1 class="page-title-secondary">Detalii client</h1>
					</div>
					<div class="flex flex-row justify-end items-center gap-4">
						<!-- <v-btn :to="'/clienti/edit/' + client.id_client" color="teal" dark class="uppercase">
						<v-icon>mdi-pencil</v-icon>
						Editeaza
					</v-btn> -->
						<v-btn color="teal" dark class="uppercase" @click="editMode = true">
							<v-icon>mdi-pencil</v-icon>
							Editeaza
						</v-btn>
						<v-btn color="error" dark class="uppercase" @click="deleteClient(client.id_client)">
							<v-icon>mdi-delete</v-icon>
							Sterge
						</v-btn>
					</div>
				</div>
				<h1 v-else class="page-title-secondary">Editeaza client</h1>
			</div>
			<div v-if="editMode">
				<EditClient :clientData="client" @cancelEdit="editMode = false" />
			</div>
			<div v-else>
				<v-card class="py-4 px-6">
					<v-list dense>
						<v-list-item class="text-lg flex flex-row justify-start items-end gap-1">
							<span v-if="!!+client.firma">Denumire:</span>
							<span v-else>Nume:</span>
							<span class="font-medium">{{ client.nume }}</span>
						</v-list-item>
						<v-list-item class="text-lg flex flex-col justofy-start items-start">
							<div v-if="client.cui" class="flex flex-row justify-start items-end gap-1">
								<span>CUI/CIF:</span>
								<span class="font-medium">{{ client.cui }}</span>
							</div>
							<div v-if="client.reg_com" class="flex flex-row justify-start items-end gap-1">
								<span>Reg. Com.:</span>
								<span class="font-medium">{{ client.reg_com }}</span>
							</div>
							<div class="flex flex-row justify-start items-end gap-1">
								<span>Adresa:</span>
								<span class="font-medium">{{ client.adresa | NoData }}</span>
							</div>
						</v-list-item>
						<v-list-item class="text-lg flex flex-col justofy-start items-start">
							<div v-if="client.persoana" class="flex flex-row justify-start items-end gap-1">
								<span>Persoana:</span>
								<span class="font-medium">{{ client.persoana }}</span>
							</div>
							<div class="flex flex-row justify-start items-end gap-1">
								<span>Email:</span>
								<span class="font-medium">{{ client.email | NoData }}</span>
							</div>
							<div v-if="client.cc_obj != undefined && client.cc_obj.length > 0"
								class="flex flex-row justify-start items-end gap-1">
								<span>CC Email:</span>
								<span class="font-medium">
									<span v-for="item in client.cc_obj">{{ item + ", " }}</span>
								</span>
							</div>
							<div class="flex flex-row justify-start items-end gap-1">
								<span>Telefon:</span>
								<span class="font-medium">{{ client.tel }}</span>
							</div>
						</v-list-item>
					</v-list>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import EditClient from "@/components/Clients/EditClient.vue"
export default {
	name: "detaliiclient",
	components: {
		EditClient
	},
	data() {
		return {
			editMode: false
		}
	},
	computed: {
		client() {
			return this.$store.getters.client;
		},
		loadingClient() {
			return this.$store.getters.loadingClient
		}
	},
	methods: {
		deleteClient(id) {
			this.$store.dispatch("deleteClient", id);
		},
		fetchClient(id) {
			this.$store.dispatch("fetchClient", id);
		},
	},
	created: function () {
		this.fetchClient(this.$route.params.id);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.collection-item i {
	margin-right: 10px;
	vertical-align: middle;
}
</style>

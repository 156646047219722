var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"flex justify-start items-center mt-6"},[_c('div',{staticClass:"w-1/2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterInput),expression:"filterInput"}],attrs:{"placeholder":"Cauta oferta dupa client"},domProps:{"value":(_vm.filterInput)},on:{"input":function($event){if($event.target.composing)return;_vm.filterInput=$event.target.value}}})])]),(_vm.loading)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"indeterminate"})]):_vm._e(),(_vm.oferte_productie.length > 0)?_c('div',{staticClass:"card-panel"},[_c('table',{staticClass:"highlight responsive-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.filterBy(_vm.oferte_productie, _vm.filterInput)),function(oferta){return _c('tr',{key:oferta.id_cerere},[_c('td',{class:{ 'bold italic': oferta.gard_obj.model == 'vertical' }},[_vm._v(" "+_vm._s(oferta.id_cerere)+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(oferta.data)))]),_c('td',[_vm._v(" "+_vm._s(_vm.fullName(oferta.client_obj.surname, oferta.client_obj.name))+"   "+_vm._s(_vm.returnTimeframeNumber(oferta.client_obj.timeframe))+" "),_c('span',{staticClass:"dot",class:_vm.returnTimeframeColor(oferta.client_obj.timeframe)})]),_c('td',[_vm._v(_vm._s(oferta.country.toUpperCase())+" / "+_vm._s(oferta.client_obj.judet.text))]),_c('td',{staticClass:"text-center"},[(oferta.client_obj.email)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
				content: oferta.client_obj.email + '<br>' + oferta.client_obj.phone, trigger: 'click',
				placement: 'right',
				classes: ['grey', 'lighten-3', 'pa-10'],
				targetClasses: ['material-icons'],
				offset: 10,
				delay: {
					show: 500,
					hide: 300,
				},
			}),expression:"{\n\t\t\t\tcontent: oferta.client_obj.email + '<br>' + oferta.client_obj.phone, trigger: 'click',\n\t\t\t\tplacement: 'right',\n\t\t\t\tclasses: ['grey', 'lighten-3', 'pa-10'],\n\t\t\t\ttargetClasses: ['material-icons'],\n\t\t\t\toffset: 10,\n\t\t\t\tdelay: {\n\t\t\t\t\tshow: 500,\n\t\t\t\t\thide: 300,\n\t\t\t\t},\n\t\t\t}"}],staticStyle:{"cursor":"pointer"}},[_vm._v("local_phone")]):_vm._e()]),_c('td',{staticClass:"w-60"},[_vm._v(" "+_vm._s(_vm.panouriPrice(oferta))+" eur / "+_vm._s(_vm.poarta_pietonalaPrice(oferta))+" eur / "+_vm._s(_vm.poarta_autoPriceStr(oferta))+" eur ")]),_c('td',{staticClass:"w-16"},[_vm._v(" "+_vm._s(_vm.suprafataTotala( oferta.gard_obj.suprafataPanouri, oferta.gard_obj.suprafataPP, oferta.gard_obj.suprafataPA ))+" m² ")]),_c('td',[_vm._v(" "+_vm._s(oferta.gard_obj.pretTotal)+" eur ")]),_c('td',[_c('div',[_c('a',{attrs:{"href":`https://hub.cuprolli.ro/docs/configurator/eu/zaun_angebot_${oferta.id_cerere}.pdf`,"target":"_blank","download":`oferta_gard_eu_${oferta.id_cerere}`}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
				content: 'Descarca oferta',
				trigger: 'hover',
				placement: 'top',
				classes: ['teal', 'lighten-3', 'pa-10'],
				targetClasses: [
					'material-icons',
					'btn-icon',
					'teal-text',
					'align-middle',
					'ml-10',
				],
				offset: 10,
				delay: {
					show: 500,
					hide: 300,
				},
			}),expression:"{\n\t\t\t\tcontent: 'Descarca oferta',\n\t\t\t\ttrigger: 'hover',\n\t\t\t\tplacement: 'top',\n\t\t\t\tclasses: ['teal', 'lighten-3', 'pa-10'],\n\t\t\t\ttargetClasses: [\n\t\t\t\t\t'material-icons',\n\t\t\t\t\t'btn-icon',\n\t\t\t\t\t'teal-text',\n\t\t\t\t\t'align-middle',\n\t\t\t\t\t'ml-10',\n\t\t\t\t],\n\t\t\t\toffset: 10,\n\t\t\t\tdelay: {\n\t\t\t\t\tshow: 500,\n\t\t\t\t\thide: 300,\n\t\t\t\t},\n\t\t\t}"}]},[_vm._v("cloud_download")])])])])])}),0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nr.")]),_c('th',[_vm._v("Data oferta")]),_c('th',{staticClass:"w-60"},[_vm._v("Client")]),_c('th',{staticClass:"w-32"},[_vm._v("Tara / zona")]),_c('th',{staticClass:"text-center"},[_vm._v("Contact")]),_c('th',[_vm._v(" Preturi / m² "),_c('br'),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v("panouri / poarta mica / poarta auto")])]),_c('th',[_vm._v(" Suprafata "),_c('br'),_vm._v(" totala ")]),_c('th',[_vm._v("Pret total cu TVA")]),_c('th')])])
}]

export { render, staticRenderFns }
<template>
	<div>
		<h6 class="bold">Trimite lista materialelor catre furnizori</h6>
		<table class="responsive-table materiale-table">
			<thead>
				<tr class="head">
					<th class="center-align">Nr.</th>
					<th>Denumire produs</th>
					<th class="center-align">Cantitate</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(produs, index) in matFurnizor">
					<td class="width5 center-align">{{ index + 1 }}</td>
					<td><input type="text" v-model="produs.denumire" /></td>
					<td class="width20">
						<input
							class="center-align"
							type="text"
							v-model="produs.cantitate"
						/>
					</td>
					<td class="width3 delete right" @click="deleteProductFurnizor(index)">
						X
					</td>
				</tr>
			</tbody>
		</table>
		<br />
		<div>
			<span class="bold">Lista adrese email furnizori: </span>
			<div v-if="listaEmailFurnizori.length > 0" class="inline-div">
				<span v-for="(item, index) in listaEmailFurnizori">
					{{ item }}
					<button
						class="btn-text right-margin"
						@click.prevent="deleteFurnizorList(index)"
					>
						<i class="icon-btn red-text material-icons">close</i>
					</button>
				</span>
			</div>
			<div v-else class="inline-div">–</div>
		</div>
		<a class="btn-text teal-text" @click="openAddFurnizorEmail()"
			>Adauga furnizor in lista</a
		>
		<div v-if="visibleAddFurnizor" class="mtop-10">
			<div class="width50 inline-div mbottom-20">
				<model-list-select
					id="furnizor"
					:list="furnizori"
					option-text="text"
					option-value="value"
					:custom-text="furnizorName"
					v-model="furnizor"
					placeholder="Alege furnizor"
				></model-list-select>
			</div>
			<div class="inline-div">
				<button class="btn-small" v-on:click="addFurnizorList()">
					<i class="material-icons left">add</i>Adauga in lista
				</button>
			</div>
		</div>
		<div class="mtop-20">
			<a href="#!" class="modal-action modal-close btn grey">Inchide</a>
			<div class="right">
				<button
					class="btn blue-grey modal-close"
					v-on:click="createMaterialePDF(1)"
				>
					<i class="material-icons left">file_download</i>PDF
				</button>
				<button
					class="btn lighten-2 modal-close"
					v-on:click="createMaterialePDF(2)"
				>
					<i class="material-icons left">email</i>Trimite PDF
				</button>
			</div>
		</div>
		<!-- <pre>{{matFurnizor}}</pre> -->
	</div>
</template>

<script>
import { ModelListSelect } from "vue-search-select";
import images from "../../assets/oferta_images.js";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";

export default {
	name: "MaterialeFurnizori",
	data() {
		return {
			visibleAddFurnizor: false,
			furnizor: {
				value: "",
				text: "",
			},
			listaEmailFurnizori: [],
		};
	},
	props: ["matFurnizor", "idOferta"],
	computed: {
		furnizori() {
			return this.$store.getters.selectFurnizori;
		},
	},
	methods: {
		furnizorName(item) {
			return `${item.text} - ${item.email}`;
		},
		addFurnizorList() {
			this.listaEmailFurnizori.push(this.furnizor.email);
			this.visibleAddFurnizor = false;
			this.furnizor = {};
		},
		deleteFurnizorList(index) {
			this.listaEmailFurnizori.splice(index, 1);
		},
		openAddFurnizorEmail() {
			this.visibleAddFurnizor = true;
		},
		deleteProductFurnizor(index) {
			if (this.matFurnizor.length > 1) {
				this.matFurnizor.splice(index, 1);
			}
		},
		createMaterialePDF(de) {
			let pdfName = "Cerere oferta nr." + this.idOferta;
			var logo = images.data.logo;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);
			doc.setFontSize(9);

			// Document header
			// Logo
			doc.addImage(logo, "JPEG", 8, 8, 27, 12);
			doc.setFont("Roboto", "normal");
			// Header info
			doc.setFont("Roboto", "bold");
			doc.text("CUI: ", 8, 30);
			doc.text("Nr. Reg. Com.: ", 8, 35);
			doc.text("Adresa: ", 8, 40);
			// doc.text("Email: ", 170, 30);
			// doc.text("Web: ", 170, 35);
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 8, 25);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text("RO 35671874", 15, 30);
			doc.text("J35/468/2016", 31, 35);
			doc.text("Str. Caprioarei, nr.11", 21, 40);
			doc.text("307200 Ghiroda, jud. Timis", 8, 45);
			// doc.text("office@cuprolli.ro", 180, 30);
			// doc.text("www.cuprolli.ro", 180, 35);

			// Document body
			doc.setFontSize(12);
			doc.setTextColor(243, 153, 46);
			doc.setFont("Roboto", "bold");
			doc.text("CERERE OFERTA: nr. " + this.idOferta, 8, 60);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.setFont("Roboto", "normal");

			let space = 66;

			// Products table
			//
			if (this.matFurnizor) {
				doc.setFont("Roboto", "bold");
				doc.text(
					"Va rugam sa ne trimiteti o oferta detaliata pentru urmatoarele produse: ",
					8,
					space
				);
				doc.setFontSize(8);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 15);
				doc.text("Denumire produs", 15, space + 15);
				doc.text("Cantitate", 138, space + 15);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 17, 160, space + 17);
				space = space + 23;
				doc.setFont("Roboto", "normal");
				let nr = 0;
				this.matFurnizor.forEach(function(produs) {
					nr = nr + 1;
					doc.text(nr + "", 9, space);
					doc.text(produs.denumire, 15, space);
					doc.text(produs.cantitate, 141, space);
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 160, space + 3);
					space = space + 8;
				});
				space = space + 15;
			}

			// Save PDF file
			if (de == 1) {
				doc.save(pdfName + ".pdf");
			} else if (de == 2) {
				if (this.listaEmailFurnizori.length < 1) {
					alert("Alegeti cel putin un furnizor");
				} else {
					var pdf = btoa(doc.output());
					// Send doc to php script for emailing forward
					this.$http
						.post(
							process.env.VUE_APP_HUB_API_HOST +
								"email/productie/cerere_furnizor",
							{
								data: pdf,
								id_cerere: this.idOferta,
								furnizori_emails: JSON.stringify(this.listaEmailFurnizori),
							},
							{
								headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
							}
						)
						.then(function(response) {
							console.log(response.body);
							// M.toast({ html: response.body, classes: "blue" });
						})
						.catch(function(error) {
							console.log(error.body);
							M.toast({
								html: "Eroare! Emailul nu a fost trimis",
								classes: "red darken-1",
							});
						});
				}
			}
		},
	},
	components: {
		ModelListSelect,
	},
	created: function() {
		this.$store.dispatch("fetchFurnizori");
	},
	mounted: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table tr {
	border-bottom: none;
}
table tr.head {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table td {
	padding: 0 5px;
}
table input[type="text"]:not(.browser-default) {
	margin: 0;
}
td.delete {
	padding: 15px 15px 15px 0;
	cursor: pointer;
	color: #e65100;
}
</style>

<template>
  <div class="container pb-10">
    <div class="flex justify-between items-center py-2 mb-4">
      <h1 class="page-title">Lista reveniri configurator RO</h1>
    </div>

    <v-dialog v-model="visibleReturnDateModal" max-width="400px">
      <v-card>
        <v-form ref="changeReturnDateForm" v-model="returnDateForm" @submit.prevent="changeReturnDate">
          <v-card-title>
            Modifica data revenire
          </v-card-title>
          <v-card-text>
            <v-menu v-model="datepickerMenu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="formattedReturnDate" label="Data revenire" prepend-inner-icon="mdi-calendar"
                  readonly outlined :rules="[rules.required]" v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="currentReturnItemDate.return_date" no-title :rules="[rules.required]"
                @input="datepickerMenu = false"></v-date-picker>
            </v-menu>
          </v-card-text>
          <div class="flex flex-row justify-end gap-2 px-6 pb-4">
            <v-btn color="blue-grey" class="uppercase" dark large @click="closeReturnDateModal">Anuleaza</v-btn>
            <v-btn color="teal" dark class="uppercase" large type="submit">Salveaza</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="visibleReturnModal" max-width="400px">
      <v-card>
        <v-form ref="changeReturnForm" v-model="returnForm" @submit.prevent="changeReturnStatus">
          <v-card-title>
            Schimba statusul
          </v-card-title>
          <v-card-text>
            <v-select v-model="currentReturnItem.completed" :items="returnItemStatusList" label="Alege" outlined
              :rules="[rules.required]">
            </v-select>
            <v-text-field v-model="currentReturnItem.obs" label="Detalii suplimentare" outlined></v-text-field>
          </v-card-text>
          <div class="flex flex-row justify-end gap-2 px-6 pb-4">
            <v-btn color="blue-grey" class="uppercase" dark large @click="closeReturnModal">Anuleaza</v-btn>
            <v-btn color="teal" dark class="uppercase" large type="submit">Salveaza</v-btn>
          </div>
        </v-form>
        <!-- {{ currentReturnItem }} -->
      </v-card>
    </v-dialog>

    <v-toolbar flat color="light-grey" class="rounded mb-4">
      <div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
        <v-text-field v-model="filterInput" clearable flat solo hide-details prepend-inner-icon="mdi-magnify"
          label="Cauta revenire dupa client"></v-text-field>
      </div>
    </v-toolbar>

    <div class="flex flex-row justify-between items-center">
      <h2 class="text-xl font-medium mb-4">Reveniri active</h2>
      <div class="bg-white rounded flex flex-grow-0 gap-1 p-2">
        <div>
          <v-badge bordered color="error" inline>
            <template v-slot:badge>
              <span class="font-medium">{{ countDue }}</span>
            </template>
          </v-badge>
          Depasite
        </div>
        <div>
          <v-badge bordered color="warning" inline>
            <template v-slot:badge>
              <span class="font-medium">{{ countToday }}</span>
            </template>
          </v-badge>
          Astazi
        </div>
      </div>
    </div>
    <v-data-table class="mb-10" :headers="headers" :items="filterBy(activeReveniri, filterInput)" item-key="id"
      :loading="loadingListaReveniriConfigurator" show-expand single-expand :expanded.sync="expanded_active"
      :items-per-page="-1" hide-default-footer>
      <template v-slot:item.nume="{ item }">
        {{ `${item.client_obj.surname} ${item.client_obj.name}` }}
      </template>
      <template v-slot:item.returnDate="{ item }">
        <ReturnDate :return-date="item.return_date" :today="today" :colored="true" />
      </template>
      <template v-slot:item.addDate="{ item }">
        {{ item.add_date | formatDate }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td colspan="2" class="bg-gray-100">
          <v-icon class="mx-1">mdi-arrow-right-bottom</v-icon>
        </td>
        <td :colspan="7" class="bg-gray-100">
          <div v-if="item.obs">Detalii: {{ item.obs }}</div>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <Actions :item="item" :user-role="user.role" @openDateModal="loadCurrentReturnDate"
          @openStatusModal="loadCurrentReturnStatus" />
      </template>
    </v-data-table>

    <h2 class="text-xl font-medium mb-4">Reveniri rezolvate</h2>
    <v-data-table class="mb-10" :headers="headers" :items="filterBy(completedReveniri, filterInput)" item-key="id"
      :loading="loadingListaReveniriConfigurator" show-expand single-expand :expanded.sync="expanded_completed"
      :items-per-page="-1" hide-default-footer>
      <template v-slot:item.nume="{ item }">
        {{ `${item.client_obj.surname} ${item.client_obj.name}` }}
      </template>
      <template v-slot:item.returnDate="{ item }">
        <ReturnDate :return-date="item.return_date" :today="today" :colored="false" />
      </template>
      <template v-slot:item.addDate="{ item }">
        {{ item.add_date | formatDate }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td colspan="2" class="bg-gray-100">
          <v-icon class="mx-1">mdi-arrow-right-bottom</v-icon>
        </td>
        <td :colspan="7" class="bg-gray-100">
          <div v-if="item.obs">Detalii: {{ item.obs }}</div>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <Actions :item="item" :user-role="user.role" @openDateModal="loadCurrentReturnDate"
          @openStatusModal="loadCurrentReturnStatus" />
      </template>
    </v-data-table>

  </div>
</template>

<script>
import moment from "moment";
import ReturnDate from "./ReturnsList/ReturnDate";
import Actions from "./ReturnsList/Actions";

export default {
  components: {
    ReturnDate,
    Actions
  },
  data() {
    return {
      returnForm: false,
      returnDateForm: false,
      filterInput: "",
      visibleReturnModal: false,
      visibleReturnDateModal: false,
      datepickerMenu: false,
      today: new Date().toISOString().substring(0, 10),
      currentReturnItem: {
        id: null,
        completed: null,
        obs: null
      },
      currentReturnItemDate: {
        id: null,
        return_date: null
      },
      returnItemStatusList: [
        {
          text: 'Activa',
          value: "0"
        },
        {
          text: 'Rezolvata',
          value: "1"
        }
      ],
      rules: {
        required: value => !!value || 'Câmp obligatoriu'
      },
      expanded_active: [],
      expanded_completed: [],
      headers: [
        {
          text: 'ID cerere',
          align: 'start',
          sortable: false,
          value: 'cerere_id'
        },
        {
          text: 'Client',
          align: 'start',
          sortable: false,
          value: 'nume',
        },
        {
          text: 'Data revenire',
          align: 'start',
          sortable: false,
          value: 'returnDate',
        },
        {
          text: 'Data adaugat',
          align: 'start',
          sortable: false,
          value: 'addDate',
        },
        {
          text: 'Adaugat de',
          sortable: false,
          value: 'username'
        },
        {
          value: 'data-table-expand',
          width: '5%'
        },
        {
          value: 'actions',
          sortable: false,
          width: '5%'
        }
      ]
    };
  },
  computed: {
    loadingListaReveniriConfigurator() {
      return this.$store.loadingListaReveniriConfigurator
    },
    activeReveniri() {
      return this.$store.getters.lista_reveniri_configurator_gard_active
    },
    countDue() {
      const activeDue = this.activeReveniri.filter(item => moment(item.return_date).isBefore(moment(this.today)))
      return activeDue.length
    },
    countToday() {
      const activeDue = this.activeReveniri.filter(item => moment(item.return_date).isSame(moment(this.today), 'day'))
      return activeDue.length
    },
    completedReveniri() {
      return this.$store.getters.lista_reveniri_configurator_gard_completed
    },
    user() {
      return this.$store.getters.loggedUser;
    },
    formattedReturnDate() {
      return this.$options.filters.formatDate(this.currentReturnItemDate.return_date)
    }
  },
  methods: {
    escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    },
    fullName(firstname, lastname) {
      return `${firstname} ${lastname}`;
    },
    filterBy(list, value) {
      if (!value) {
        value = ""
      }
      const words = value
        .split(/\s+/g)
        .map((s) => s.trim())
        .filter((s) => !!s);

      const hasTrailingSpace = value.endsWith(" ");

      const searchRegex = new RegExp(
        words
          .map((word, i) => {
            if (i + 1 === words.length && !hasTrailingSpace) {
              // The last word - ok with the word being "startswith"-like
              return `(?=.*\\b${this.escapeRegExp(word)})`;
            } else {
              // Not the last word - expect the whole word exactly
              return `(?=.*\\b${this.escapeRegExp(word)}\\b)`;
            }
          })
          .join("") + ".+",
        "i"
      );
      return list.filter((oferta) => searchRegex.test(this.fullName(oferta.client_obj.surname, oferta.client_obj.name)))
    },
    closeReturnDateModal() {
      this.visibleReturnDateModal = false
      this.$nextTick(() => {
        this.currentReturnItemDate = Object.assign({}, {})
      })
    },
    closeReturnModal() {
      this.visibleReturnModal = false
      this.$nextTick(() => {
        this.currentReturnItem = Object.assign({}, {})
      })
    },
    loadCurrentReturnDate(payload) {
      this.currentReturnItemDate = Object.assign({}, { ...payload })
      this.visibleReturnDateModal = true
    },
    loadCurrentReturnStatus(payload) {
      this.currentReturnItem = Object.assign({}, { ...payload })
      this.visibleReturnModal = true
    },
    async changeReturnDate() {
      const valid = await this.$refs.changeReturnDateForm.validate();

      if (valid) {
        this.$store.dispatch("updateReturnItemDate", {
          id: this.currentReturnItemDate.id,
          return_date: this.currentReturnItemDate.return_date
        });
        this.closeReturnDateModal();
      }
    },
    async changeReturnStatus() {
      const valid = await this.$refs.changeReturnForm.validate();

      if (valid) {
        this.$store.dispatch("updateReturnItemStatus", {
          id: this.currentReturnItem.id,
          completed: this.currentReturnItem.completed,
          obs: this.currentReturnItem.obs
        });
        this.closeReturnModal();
      }
    }
  },
  created() {
    this.$store.dispatch("fetchListaReveniriConfiguratorGard");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

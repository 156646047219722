<template>
	<div class="container">
		<div class="flex justify-start items-center mt-6">
			<div class="w-1/2">
				<input placeholder="Cauta oferta dupa client" v-model="filterInput" />
			</div>
		</div>
		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>

		<div v-if="oferte_productie.length > 0" class="card-panel">
			<table class="highlight responsive-table">
				<thead>
					<tr>
						<th>Nr.</th>
						<th>Data oferta</th>
						<th class="w-60">Client</th>
						<th class="w-32">Tara / zona</th>
						<th class="text-center">Contact</th>
						<th>
							Preturi / m² <br />
							<span style="font-weight: 400">panouri / poarta mica / poarta auto</span>
						</th>
						<th>
							Suprafata <br />
							totala
						</th>
						<th>Pret total cu TVA</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="oferta in filterBy(oferte_productie, filterInput)" :key="oferta.id_cerere">
						<td :class="{ 'bold italic': oferta.gard_obj.model == 'vertical' }">
							{{ oferta.id_cerere }}
						</td>
						<td>{{ oferta.data | formatDate }}</td>
						<td>
							{{ fullName(oferta.client_obj.surname, oferta.client_obj.name) }}
							&nbsp; {{ returnTimeframeNumber(oferta.client_obj.timeframe) }}
							<span class="dot" :class="returnTimeframeColor(oferta.client_obj.timeframe)"></span>
						</td>
						<td>{{ oferta.country.toUpperCase() }} / {{ oferta.client_obj.judet.text }}</td>
						<td class="text-center">
							<i v-if="oferta.client_obj.email" style="cursor: pointer" v-tooltip="{
					content: oferta.client_obj.email + '<br>' + oferta.client_obj.phone, trigger: 'click',
					placement: 'right',
					classes: ['grey', 'lighten-3', 'pa-10'],
					targetClasses: ['material-icons'],
					offset: 10,
					delay: {
						show: 500,
						hide: 300,
					},
				}">local_phone</i>
						</td>
						<td class="w-60">
							{{ panouriPrice(oferta) }} eur /
							{{ poarta_pietonalaPrice(oferta) }} eur /
							{{ poarta_autoPriceStr(oferta) }} eur
						</td>
						<td class="w-16">
							{{
					suprafataTotala(
						oferta.gard_obj.suprafataPanouri,
						oferta.gard_obj.suprafataPP,
						oferta.gard_obj.suprafataPA
					)
				}}
							m²
						</td>
						<td>
							{{ oferta.gard_obj.pretTotal }} eur
						</td>
						<td>
							<div>
								<a :href="`https://hub.cuprolli.ro/docs/configurator/eu/zaun_angebot_${oferta.id_cerere}.pdf`"
									target="_blank" :download="`oferta_gard_eu_${oferta.id_cerere}`">
									<i v-tooltip="{
					content: 'Descarca oferta',
					trigger: 'hover',
					placement: 'top',
					classes: ['teal', 'lighten-3', 'pa-10'],
					targetClasses: [
						'material-icons',
						'btn-icon',
						'teal-text',
						'align-middle',
						'ml-10',
					],
					offset: 10,
					delay: {
						show: 500,
						hide: 300,
					},
				}">cloud_download</i>
								</a>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- <pre>
			{{ oferte_productie }}
		</pre> -->
	</div>
</template>

<script>

export default {
	name: "OferteProductieConfiguratorEU",
	components: {
	},
	data() {
		return {
			filterInput: "",
			prices: {
				p_rama: 1.27, // %
				pp: 237, // eur
				pa: 126, // eur
				pap: 254, // eur
				shippingKM: 0.6,
			},
		};
	},
	computed: {
		oferte_productie() {
			return this.$store.getters.oferte_productie_configurator_eu;
		},
		loading() {
			return this.$store.getters.loading;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
	},
	methods: {
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter((oferta) => {
				if (this.user.rol == "user_productie") {
					return (
						this.fullName(oferta.client_obj.surname, oferta.client_obj.name)
							.toLowerCase()
							.indexOf(value) > -1 &&
						this.arr_trading.includes(oferta.client_obj.judet.text)
					);
				} else {
					return (
						this.fullName(oferta.client_obj.surname, oferta.client_obj.name)
							.toLowerCase()
							.indexOf(value) > -1
					);
				}
			});
		},
		fullName(firstname, lastname) {
			return `${firstname} ${lastname}`
		},
		returnTimeframeNumber(str) {
			if (str) {
				if (str == "so bald wie möglich") {
					return 1;
				} else if (str == "bald") {
					return 2;
				} else if (str == "innerhalb eines halben Jahres") {
					return 3;
				} else if (str == "Ich schaue es mir gerade erst an") {
					return 4;
				}
			}
		},
		returnTimeframeColor(str) {
			if (str) {
				if (str == "so bald wie möglich" || str == "bald") {
					return "green";
				} else {
					return "grey";
				}
			}
		},
		panouriPrice(oferta) {
			let price = oferta.client_obj.judet.price;
			if (oferta.gard_obj.configuration.panouri.panelFrame) {
				price = (price * this.prices.p_rama).toFixed(2);
			}
			if (oferta.gard_obj.model == "vertical") {
				price += 15;
			}
			return Number(price);
		},
		poarta_pietonalaPrice(oferta) {
			let price = oferta.client_obj.judet.price + this.prices.pp;
			if (oferta.gard_obj.model == "vertical") {
				price += 15;
			}
			return price;
		},
		poarta_autoPriceStr(oferta) {
			let pricePA = oferta.client_obj.judet.price + this.prices.pa;
			let pricePAP = oferta.client_obj.judet.price + this.prices.pap;

			if (oferta.gard_obj.model == "vertical") {
				pricePA += 15;
				pricePAP += 15;
			}
			let str = "";

			if (oferta.gard_obj.configuration.poarta_a.items) {
				if (this.poarta_aap_buc(oferta.gard_obj.configuration) > 0) {
					if (
						this.poarta_ab_buc(oferta.gard_obj.configuration) > 0 ||
						this.poarta_ac_buc(oferta.gard_obj.configuration) > 0
					) {
						str = `${pricePA} & ${pricePAP}`;
					} else {
						str = `${pricePAP}`;
					}
				} else {
					str = `${pricePA}`;
				}
			} else {
				str = `${pricePA}`;
			}
			return str;
		},
		poarta_ab_buc(configuration) {
			let count = 0;
			configuration.poarta_a.items.forEach((poarta) => {
				if (poarta.tip == "batanta") {
					count++;
				}
			});
			return count;
		},
		poarta_ac_buc(configuration) {
			let count = 0;
			configuration.poarta_a.items.forEach((poarta) => {
				if (poarta.tip == "culisanta") {
					count++;
				}
			});
			return count;
		},
		poarta_aap_buc(configuration) {
			let count = 0;
			configuration.poarta_a.items.forEach((poarta) => {
				if (poarta.tip == "autoportanta") {
					count++;
				}
			});
			return count;
		},
		suprafataTotala(a, b, c) {
			let s = Number(a) + Number(b) + Number(c);
			return s.toFixed(2);
		},
	},
	created: function () {
		this.$store.dispatch("fetchOferteProductieConfiguratorEU");
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.dot {
	border-radius: 100px;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin: 0 5px 0 15px;
}

span.square {
	width: 12px;
	height: 12px;
	display: inline-block;
	margin: 0 5px 0 10px;
}
</style>

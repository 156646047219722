<template>
	<div>
		<p class="text-xl font-bold mb-4">
			Genereaza contractul de executie lucrari
		</p>
		<h5 class="text-sm text-gray-500 font-bold uppercase pb-4">
			Detalii suplimentare
		</h5>
		<form v-on:submit.prevent="generateContract">
			<div class="mb-0">
				<div class="input-field">
					<input id="denumire_generica" type="text" class="validate" v-model="contract_details.denumire"
						required />
					<label for="denumire_generica" class="active">Denumirea generica in contract</label>
				</div>
			</div>
			<div v-if="!oferta_productie.persoana">
				<div class="flex items-center">
					<label>Date identificare persoana</label>
					<div class="radio-btns ml-10">
						<label>
							<input name="group1" type="radio" value="CI" v-model="tip_document" />
							<span>CI</span>
						</label>
						<label>
							<input name="group1" type="radio" value="ALT" v-model="tip_document" />
							<span>Alt doc</span>
						</label>
					</div>
				</div>
				<div class="flex flex-col md:flex-row flex-wrap -mx-1 mt-4">
					<div class="input-field w-3/12 my-0 mx-1">
						<input id="cnp" type="text" v-model="contract_details.cnp" />
						<label for="cnp">CNP</label>
					</div>
					<div v-if="tip_document == 'CI'" class="input-field w-1/12 my-0 mx-1">
						<input id="serieCI" type="text" maxlength="2" class="validate" v-model="contract_details.serie_ci"
							required />
						<label for="serieCI">Serie CI</label>
					</div>
					<div v-if="tip_document == 'CI'" class="input-field w-1/12 my-0 mx-1">
						<input id="numarCI" type="text" maxlength="6" pattern="\d*" class="validate"
							v-model="contract_details.numar_ci" required />
						<label for="numarCI">Numar CI</label>
					</div>
					<div v-else class="input-field w-5/12 my-0 mx-1">
						<input id="altdoc" type="text" class="validate" v-model="contract_details.alt_document" />
						<label for="altdoc">Document</label>
					</div>
				</div>

				<div class="input-field">
					<input id="adresa" type="text" class="validate" v-model="contract_details.adresa" required />
					<label for="adresa" :class="{ active: activeLabelAdresaContract }">{{
						contract_details.alta_locatie
						? "Adresa domiciliu"
						: "Adresa domiciliu si montaj"
					}}</label>
				</div>
				<label>
					<input type="checkbox" v-model="contract_details.alta_locatie" />
					<span>Alta adresa pentru montaj</span>
				</label>
				<div v-if="contract_details.alta_locatie" class="input-field">
					<input id="locatie" type="text" class="validate" v-model="contract_details.locatie" required />
					<label for="locatie">Adresa montaj</label>
				</div>
			</div>
			<div v-else class="row">
				<div class="input-field">
					<input id="adresa" type="text" class="validate" v-model="contract_details.adresa" required />
					<label for="adresa" :class="{ active: activeLabelAdresaContract }">Adresa montaj</label>
				</div>
			</div>
			<div>
				<label>
					<input type="checkbox" v-model="montaj" />
					<span class="text-base font-bold">Montaj separat (alta firma)</span>
				</label>
			</div>
			<div class="flex items-center mt-3">
				<label class="text-lg">Este finalizata partea de finisaje?</label>
				<div class="radio-btns ml-10">
					<label>
						<input name="group2" type="radio" :value="true" v-model="contract_details.finisaj_finalizat" />
						<span>DA</span>
					</label>
					<label>
						<input name="group2" type="radio" :value="false" v-model="contract_details.finisaj_finalizat" />
						<span>NU</span>
					</label>
				</div>
			</div>
			<div class="mt-4">
				<button href="#!" class="btn cyan darken-3" type="submit">
					<i class="material-icons left">backup</i>Genereaza
				</button>
			</div>
		</form>
		<!-- <div>
			{{ contract_details }}
		</div> -->
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "AddContractPorductie",
	components: {},
	computed: {
		oferta_productie() {
			return this.$store.getters.currentOfertaProductie;
		},
		activeLabelAdresaContract() {
			if (
				this.oferta_productie.adresa == null ||
				this.oferta_productie.adresa == ""
			) {
				return false;
			} else {
				return true;
			}
		},
		montaj: {
			get() {
				return !this.oferta_productie.persoana;
			},
			set(val) {
				this.contract_details.montaj_separat = val;

				this.contract_details.firma_montaj = val
					? this.firma_montaj_activa.id_firma
					: val;
			},
		},
		firma_montaj_activa() {
			return this.$store.getters.firma_montaj_activa;
		},
	},
	data() {
		return {
			today: moment().format("YYYY-MM-DD"),
			contract_details: {
				denumire: "gard din aluminiu vopsit electrostatic",
				serie_ci: "",
				numar_ci: "",
				alt_document: "",
				cnp: "",
				adresa: this.oferta_address,
				alta_locatie: false,
				montaj_separat: false,
				firma_montaj: false,
				finisaj_finalizat: true,
				locatie: "",
			},
			tip_document: "CI",
		};
	},
	watch: {},
	methods: {
		oferta_address() {
			let address = this.oferta_productie.adresa;
			if (this.oferta_productie.oras != null) {
				address += `, ${this.oferta_productie.oras}`;
			}
			this.contract_details.adresa = address;
		},
		generateContract() {
			let newContract = {
				client_id: this.oferta_productie.id_client,
				oferta_id: this.oferta_productie.id_oferta,
				oferta_data: this.oferta_productie.data,
				user_id: this.oferta_productie.id_user,
				data: this.today,
				detalii: JSON.stringify(this.contract_details),
			};
			// console.log(newContract);
			// save Contract
			this.$store.dispatch("addContractProductie", newContract);
		},
	},
	created: function () {
		this.$store.dispatch("fetchFirmeMontaj");
	},
	mounted: function () {
		this.contract_details.montaj_separat = !this.oferta_productie.persoana;
		this.contract_details.firma_montaj = this.firma_montaj_activa.id_firma;
		this.oferta_address();
	},
	updated: function () { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
